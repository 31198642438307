.auth-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.auth-wrapper form {
  width: 375px;
  margin: 0 auto;
}

.auth-wrapper h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.auth-wrapper .form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

.auth-wrapper p {
  color: #bf1650;
}

.auth-wrapper p::before {
  display: inline;
  content: '\26A0   ';
}

.auth-wrapper input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.auth-wrapper label {
  line-height: 2;
  text-align: left;
  display: block;
  margin: 5px 0;
  color: black;
  font-size: 14px;
  font-weight: 200;
}

.auth-wrapper button[type='submit'],
input[type='submit'] {
  background: #7a84eb;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

.message-form-button {
  background: #7a84eb;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

.message-form-button:hover {
  background: #636ee6;
}

.auth-wrapper button[type='submit']:hover,
input[type='submit']:hover {
  background: #636ee6;
}

.auth-wrapper button[type='submit']:active,
input[type='button']:active,
input[type='submit']:active {
  transition: 0.3s all;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.auth-wrapper input:disabled {
  opacity: 0.4;
}

.auth-wrapper input[type='button']:hover {
  transition: 0.3s all;
}

.auth-wrapper button[type='submit'],
input[type='button'],
input[type='submit'] {
  -webkit-appearance: none;
}

.auth-wrapper .App {
  max-width: 600px;
  margin: 0 auto;
}

.auth-wrapper button[type='button'] {
  display: block;
  -webkit-appearance: none;
          appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.auth-wrapper hr {
  margin-top: 30px;
}

.auth-wrapper button {
  display: block;
  -webkit-appearance: none;
          appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.skeleton {
  position: relative;
  overflow: hidden;
  height: 40px;
  margin-bottom: 10px;
}

.skeleton::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  -webkit-animation: sweep 2s infinite;
          animation: sweep 2s infinite;
  background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.4), transparent);
}

@-webkit-keyframes sweep {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes sweep {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.skeleton-avatar {
  height: 35px;
  width: 35px;
  border-radius: 3px;
  background-color: rgba(58, 57, 57, 0.3);
}

.skeleton-author {
  background-color: rgba(58, 57, 57, 0.3);
  width: 120px;
  height: 10px;
  border-radius: 3px;
  position: absolute;
  bottom: 30px;
  left: 40px;
  right: 0;
}

.skeleton-description {
  background-color: rgba(58, 57, 57, 0.3);
  height: 20px;
  border-radius: 3px;
  position: absolute;
  bottom: 5px;
  left: 40px;
  right: 20px;
}

